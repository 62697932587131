import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from './toaster.service';
import { LoaderService } from '@common/components/common-loader/loader.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPassword } from '@app/modules/auth/models/auth.model';
import { environment } from '@root/environments/environment';

/**
 * Configuration for overriding default api gateway properties
 */
export interface APIConfig {
    isLoaderVisible?: boolean;
    isErrorMessageToasterNeeded?: boolean;
    isSuccessMessageToasterNeeded?: boolean;
    successMessage?: string;
    errorMessage?: string;
}

@Injectable({ providedIn: 'root' })
export class ApiGatewayService {
    private readonly baseUrl = environment.baseUrl;
    private readonly verifyUserEndPoint = this.baseUrl + '/auth/verify-user';
    private readonly checkEmailExistence = this.baseUrl + '/email/checkExistence';

    private readonly sendPasswordResetEmail = this.baseUrl + '/email/sendResetPasswordLink';
    private readonly setUserPassword = this.baseUrl + '/auth/setPassword';

    constructor(
        private _Http: HttpClient,
        private _ToasterService: ToasterService,
        private _LoaderService: LoaderService
    ) {}

    doPost(url: string, request: any, config?: APIConfig): Observable<any> {
        return new Observable(observer => {
            this.isLoaderNeeded(config);
            this._Http.post(url, request, { observe: 'response' }).subscribe({
                next: response => {
                    this.displayErrorToaster(config, response.status);
                    this.displaySuccessToaster(config, response.status);
                    this.resetLoader();
                    observer.next(response.body);
                    observer.complete();
                },
                error: () => {
                    this.resetLoader();
                    this.displayErrorToaster(config, null);
                    observer.next(null);
                    observer.complete();
                }
            });
        });
    }

    doGet(url: string, config?: APIConfig): Observable<any> {
        return new Observable(observer => {
            this.isLoaderNeeded(config);
            this._Http.get(url, { observe: 'response' }).subscribe({
                next: response => {
                    this.displayErrorToaster(config, response.status);
                    this.displaySuccessToaster(config, response.status);
                    this.resetLoader();
                    observer.next(response.body);
                    observer.complete();
                },
                error: () => {
                    this.resetLoader();
                    this.displayErrorToaster(config, null);
                    observer.next(null);
                    observer.complete();
                }
            });
        });
    }

    doPut(url: string, request: any, config?: APIConfig): Observable<any> {
        return new Observable(observer => {
            this.isLoaderNeeded(config);
            this._Http.put(url, request, { observe: 'response' }).subscribe({
                next: response => {
                    this.displayErrorToaster(config, response.status);
                    this.displaySuccessToaster(config, response.status);
                    this.resetLoader();
                    observer.next(response.body);
                    observer.complete();
                },
                error: () => {
                    this.resetLoader();
                    this.displayErrorToaster(config, null);
                    observer.next(null);
                    observer.complete();
                }
            });
        });
    }

    doDelete(url: string, config?: APIConfig): Observable<any> {
        return new Observable(observer => {
            this.isLoaderNeeded(config);
            this._Http.delete(url, { observe: 'response' }).subscribe({
                next: response => {
                    this.displayErrorToaster(config, response.status);
                    this.displaySuccessToaster(config, response.status);
                    this.resetLoader();
                    observer.next(response.body);
                    observer.complete();
                },
                error: () => {
                    this.resetLoader();
                    this.displayErrorToaster(config, null);
                    observer.next(null);
                    observer.complete();
                }
            });
        });
    }

    verifyUser(refText: string) {
        return this._Http.get<any>(`${this.verifyUserEndPoint}?code=${refText}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    checkUserEmailExistence(obj: UserPassword) {
        return this._Http.post<any>(`${this.checkEmailExistence}`, obj).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    sendPasswordResetLink(obj: UserPassword) {
        return this._Http.post<any>(`${this.sendPasswordResetEmail} `, obj).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    savePassword(obj: UserPassword) {
        return this._Http.post<any>(`${this.setUserPassword}`, obj).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    private isLoaderNeeded(config: any): void {
        if (config && config.isLoaderVisible) {
            this._LoaderService.showLoader();
        }
    }

    private resetLoader(): void {
        this._LoaderService.reset();
    }

    private displayErrorToaster(config: any, statusCode: any): void {
        if (
            statusCode != 200 &&
            config &&
            config.isErrorMessageToasterNeeded &&
            config.errorMessage &&
            config.errorMessage.trim().length > 0
        ) {
            this._ToasterService.openWarningToaster('Login Failed', config.errorMessage);
        }
    }

    private displaySuccessToaster(config: any, statusCode: number): void {
        if (
            statusCode == 200 &&
            config &&
            config.isSuccessMessageToasterNeeded &&
            config.successMessage &&
            config.successMessage.trim().length > 0
        ) {
            this._ToasterService.openSuccessToaster('Login Success', config.successMessage);
        }
    }
}
