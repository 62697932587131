import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from './cookies.service';
import { LoggingService } from './logging.service';
import { Observable, throwError } from 'rxjs';
import { environment } from '@root/environments/environment';

import { User } from '../_models/user';

import CryptoES from 'crypto-es';

export class LocalStorageData {
    [name: string | symbol]: any;

    adminFlag: boolean;
    clientId: number | null;
    clientName: string | null;
    currentUserId: number;
    loggedIn: boolean;
    permissions: string[];
    redirectUrl: string;

    currentUser: CurrentUser;

    private static indexedHandler: ProxyHandler<LocalStorageData> = {
        get(target, property) {
            return target[property];
        },
        set(target, property, value): boolean {
            target[property] = value;
            return true;
        },
    };

    constructor(
        adminFlag: boolean,
        clientId: number | null,
        clientName: string | null,
        currentUserId: number,
        loggedIn: boolean,
        permissions: string[],
        redirectUrl: string,
        currentUser: CurrentUser
    ) {
        this.adminFlag = adminFlag;
        this.clientId = clientId;
        this.clientName = clientName;
        this.currentUserId = currentUserId;
        this.loggedIn = loggedIn;
        this.permissions = permissions;
        this.redirectUrl = redirectUrl;

        this.currentUser = currentUser;

        return new Proxy(this, LocalStorageData.indexedHandler);
    }

    readIndexedProperty(property: string | symbol): any {
        return this[property];
    }

    public isValid() {
        let valid = true;

        if (!this.loggedIn) {
            valid = false;
        }
        if (!this.currentUserId) {
            valid = false;
        }
        if (!this.permissions) {
            valid = false;
        }
        if (!this.redirectUrl) {
            valid = false;
        }

        if (!this.currentUser || !this.currentUser.isValid()) {
            valid = false;
        }

        return valid;
    }
}

export class CurrentUser implements User {
    id: number;
    clientId?: number;
    userName: string;
    password: string;
    name?: string;
    firstName: string;
    lastName: string;
    email: string;
    isMfaEnabled: boolean;
    roleId: number;
    token?: string;
    redirectUrl?: string;

    // generate a constructor which takes all the properties of the User interface
    constructor(
        id: number,
        clientId: number | undefined,
        userName: string,
        password: string,
        name: string,
        firstName: string,
        lastName: string,
        email: string,
        isMfaEnabled: boolean,
        roleId: number,
        token: string,
        redirectUrl: string
    ) {
        this.id = id;
        this.clientId = clientId;
        this.userName = userName;
        this.password = password;
        this.name = name;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.isMfaEnabled = isMfaEnabled;
        this.roleId = roleId;
        this.token = token;
        this.redirectUrl = redirectUrl;
    }

    public isValid() {
        let valid = true;

        // if a required property is missing, set valid to false
        if (!this.id) {
            valid = false;
        }
        if (!this.redirectUrl) {
            valid = false;
        }

        return valid;
    }
}

@Injectable({
    providedIn: 'root',
})
export class LocalStorageProviderService {
    private static secret = 5225541;
    private static readonly localStorageKey = 'lb';

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private http: HttpClient,
        private permissionService: NgxPermissionsService,
        private cookieService: CookieService,
        private injector: Injector
    ) {}

    private readonly baseUrl = environment.baseUrl;
    private readonly localStorage = this.baseUrl + '/auth/localStorage';
    private readonly requestHeaderDetails = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }),
    };


    private loadLocalStorageData(): Observable<LocalStorageData> {
        const loggingService = this.injector.get(LoggingService);

        return this.http
            .get<any>(
                this.localStorage + '/' + this.cookieService.getCookie('token'),
                this.requestHeaderDetails
            )
            .pipe(
                map((response: any) => {
                    const localStorage = new LocalStorageData(
                        response.adminFlag,
                        response.clientId,
                        response.clientName,
                        response.currentUserId,
                        response.loggedIn,
                        response.permissions,
                        response.redirectUrl,
                        new CurrentUser(
                            response.currentUseId,
                            response.clientId,
                            response.userName,
                            response.password,
                            response.name,
                            response.firstName,
                            response.lastName,
                            response.email,
                            response.isMfaEnabled,
                            response.roleId,
                            response.token,
                            response.redirectUrl
                        )
                    );

                    this.setLocalStorage(localStorage);

                    if (this.getItem('loggedIn') && this.getItem('permissions')) {
                        this.permissionService.loadPermissions(
                            this.getLocalStorage()!['permissions']
                        );
                    } else if (!this.getItem('loggedIn')) {
                        this.clear();
                    }

                    return localStorage;
                }),
                catchError((error: any, caught: Observable<any>) => {
                    const message = 'An error was encountered while fetching user permissions.';

                    loggingService.error(message, error);

                    this.clear();
                    return throwError(() => new Error(message));
                })
            );
    }

    public setLocalStorage(localStorage: LocalStorageData) {
        const window = this.document.defaultView;

        if (!window) {
            throw new Error('Window is not available.');
        }

        const json = JSON.stringify(localStorage);
        const encryptedJson = CryptoES.AES.encrypt(
            json,
            LocalStorageProviderService.secret.toString()
        ).toString();

        window.localStorage.setItem(LocalStorageProviderService.localStorageKey, encryptedJson);
    }

    public isLocalStorageValid() {
        const data = this.getLocalStorage();

        if (data) {
            const localStorageData = new LocalStorageData(
                data.adminFlag,
                data.clientId,
                data.clientName,
                data.currentUserId,
                data.loggedIn,
                data.permissions,
                data.redirectUrl,
                new CurrentUser(
                    data.currentUserId,
                    data.clientId ? data.clientId : undefined,
                    data.userName,
                    data.password,
                    data.name,
                    data.firstName,
                    data.lastName,
                    data.email,
                    data.isMfaEnabled,
                    data.roleId,
                    data.token,
                    data.redirectUrl
                )
            );

            return localStorageData.isValid();
        } else {
            return false;
        }
    }

    public getLocalStorage(): LocalStorageData | null {
        const window = this.document.defaultView;

        if (!window) {
            throw new Error('Window is not available.');
        }

        const data = window.localStorage.getItem(LocalStorageProviderService.localStorageKey);

        if (!data) {
            return null;
        }

        try {
            const decryptedJson = CryptoES.AES.decrypt(
                data,
                LocalStorageProviderService.secret.toString()
            ).toString(CryptoES.enc.Utf8);

            return JSON.parse(decryptedJson);
        } catch (error) {
            // if there is an error, clear the local storage and return null
            // the local storage value is invalid (stale or tampered with)
            this.clear();

            return null;
        }
    }

    public getItem(key: string): any {
        const data = this.getLocalStorage();
        if (!data) {
            throw new Error('Local storage is not initialized.');
        }

        return data[key];
    }

    public setItem(key: string, value: any) {
        const data = this.getLocalStorage();
        if (!data) {
            throw new Error('Local storage is not initialized.');
        }
        data[key] = value;

        this.setLocalStorage(data);
    }

    public removeItem(key: string) {
        const data = this.getLocalStorage();
        if (!data) {
            throw new Error('Local storage is not initialized.');
        }
        delete data[key];

        this.setLocalStorage(data);
    }

    public clear() {
        const window = this.document.defaultView;

        if (!window) {
            throw new Error('Window is not available.');
        }

        window.localStorage.removeItem(LocalStorageProviderService.localStorageKey);
    }
}
