import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { ApiGatewayService } from './api-gateway.service';
// import { AnalyticsService } from '@app/modules/client/analytics/services/analytics.service';
import { LocalStorageProviderService } from './local-storage-provider.service';
import { CookieService } from './cookies.service';
import { environment } from '@root/environments/environment';

@Injectable({ providedIn: 'root' })
export class PermissionService {
    private permissions: any = [];
    constructor(
        private _Router: Router,
        private _ApiGatewayService: ApiGatewayService,
        private permissionService: NgxPermissionsService,
        private localStorage: LocalStorageProviderService,
        private cookieService: CookieService
    ) {}

    private readonly baseUrl = environment.baseUrl;
    private readonly getRolePermission = this.baseUrl + '/auth/getUserPermission';


    flushPermission(): void {
        this.permissionService.flushPermissions();
        this.permissions = [];
    }

    setPermissions(): Observable<string[]> {
        return new Observable(observer => {
            this.getPermissions().subscribe({
                next: response => {
                    this.permissionService.loadPermissions(response);
                    this.localStorage.setItem('permissions', response);
                    observer.next(response);
                    observer.complete();
                },
                error: () => {
                    observer.next([]);
                    observer.complete();
                    this.logout();
                }
            });
        });
    }

    canCurrentUser(permission: string) {
        if (!this.localStorage.isLocalStorageValid()) {
            return false;
        }
        this.permissions = this.localStorage.getItem('permissions');
        for (const key in this.permissions) {
            if (
                Object.prototype.hasOwnProperty.call(this.permissions, key) &&
                this.permissions[key] === permission
            ) {
                return true;
            }
        }
        return false;
    }

    getPermissions(): Observable<string[]> {
        const url: string = this.getRolePermission;
        return new Observable(observer => {
            if (this.permissions && this.permissions.length > 0) {
                observer.next(this.permissions);
                observer.complete();
            } else {
                this._ApiGatewayService
                    .doGet(url, {
                        isLoaderVisible: true,
                        isErrorMessageToasterNeeded: true,
                        errorMessage: 'Permission fetch failed!',
                    })
                    .subscribe({
                        next: resp => {
                            this.permissions = resp;
                            this.localStorage.setItem('permissions', this.permissions);
                            observer.next(resp);
                            observer.complete();
                        },
                        error: () => {
                            observer.next([]);
                            observer.complete();
                            this.logout();
                        }
                    });
            }
        });
    }

    hasPermission(permission: any): boolean {
        return this.permissionService.getPermission(permission) ? true : false;
    }

    logout() {
        // remove user from local storage to log user out
        this.localStorage.clear();
        this.cookieService.deleteCookie('token');
        this.flushPermission();
        this._Router.navigate(['/auth/login']);
    }
}
